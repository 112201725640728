
.beforetoday {
  background-color: grey;
  height: 20px;
  width: 10px;
  cursor: none;
}

.bookeddate {
  background-color: yellow;
  height: 20px;
  width: 10px;
}

.selecteddate {
  background-color: green;
  height: 20px;
  width: 10px;
}

.closebutton {
    margin-left: auto;
    width: fit-content;
    cursor: pointer;
  top: 10px;
  right: 10px;
}
.shortenedUrl{
        cursor: default;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block; 
        max-width: 100%;
}

@media (max-width:400px) {
  .beforetoday, .bookeddate, .selecteddate {
    overflow: visible; /* Ensure no text is being hidden */
    font-size: 9px;
    width: 100%;
    font-weight: bold;
    box-sizing: border-box; 
  }
}